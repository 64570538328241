@import url("https://fonts.googleapis.com/css2?family=Annapurna+SIL:wght@400;700&family=Inter:wght@100..900&family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  background: #030628;
  color: #fff;
  font-family: "Inter", sans-serif;
}

.App {
  overflow: hidden;
}

img {
  width: 100%;
}

a {
  cursor: pointer;
  color: inherit;
}

.box-shadow-custom {
  box-shadow: 0px 0px 50px 0px #3825ff;
}
